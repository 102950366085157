import {router, usePage} from "@inertiajs/vue3";
import {event} from "vue-gtag";

export const imageSize = (imageSrc, size) => {
    return imageSrc.replace('tn500.jpg', `.jpg`).replace('.jpg', `tn${size}.jpg`);
}

export const countryWhitelist = [
    'US',
    'AG', // Antigua and Barbuda
    'AR', // Argentina
    'AW', // Aruba
    'AU', // Australia
    'AT', // Austria
    'BS', // Bahamas
    'BB', // Barbados
    'BE', // Belgium
    'BM', // Bermuda
    'BR', // Brazil
    'CA', // Canada
    'CL', // Chile
    'CZ', // Czech Republic
    'DK', // Denmark
    'EE', // Estonia
    'FI', // Finland
    'FR', // France
    'DE', // Germany
    'GH', // Ghana
    'GR', // Greece
    'GU', // Guam
    'HK', // Hong Kong
    'IE', // Ireland
    'IL', // Israel
    'IT', // Italy
    'JP', // Japan
    'KR', // Korea, Republic of
    'LU', // Luxembourg
    'MX', // Mexico
    'NL', // Netherlands
    'NZ', // New Zealand
    'NO', // Norway
    'PL', // Poland
    'PT', // Portugal
    'PR', // Puerto Rico
    'RU', // Russian Federation
    'SG', // Singapore
    'ZA', // South Africa
    'ES', // Spain
    'SE', // Sweden
    'CH', // Switzerland
    'TW', // Taiwan
    'TH', // Thailand
    'TR', // Turkey
    'UA', // Ukraine
    'GB', // United Kingdom
    'UM', // United States Minor Outlying Islands
    'VG', // Virgin Islands, British
    'VI', // Virgin Islands, U.S.
];

export function addToCart(productId, quantity, onSuccess = () => {
}) {
    const previousProduct = usePage().props.cart.products.find((p) => p.productId === productId);
    router.patch(route('cart.patch'), [{
        productId: productId,
        quantity: quantity,
    }], {
        preserveScroll: true,
        preserveState: true,
        onSuccess: (e) => {
            onSuccess();
            let product = e.props.cart.products.find((p) => p.productId === productId);
            const change = (product?.cart?.quantity ?? 0) - (previousProduct?.cart?.quantity ?? 0);
            product = product ?? previousProduct;
            event(change > 0 ? 'add_to_cart' : 'remove_from_cart', {
                items: [{
                    ...productAsAnalyticItem(product),
                    quantity: Math.ceil(Math.abs(change)),
                }],
            });
        },
    });
}

export function productAsAnalyticItem(product, index) {
    return {
        item_id: product.id,
        item_name: product.title,
        brand: product.subtitle,
        // category: category: props.product.details.category[0].name,
        price: product.price / 100,
        discount: (product.stdPrice - product.price) / 100,
        index: index,
    };
}

export function removeFromFavorites(productId) {
    router.post(route('favorites.delete'), {
        productId: productId,
    }, {
        preserveScroll: true,
        preserveState: true,
    });
}

export function shippingAnalytic(method) {
    const cart = usePage().props.cart;
    event("add_shipping_info", {
        currency: "USD",
        shipping_tier: method,
        coupon: cart.coupon?.code,
        value: cart.subtotal / 100,
        items: cart.products.map((product, i) => {
            return {
                ...productAsAnalyticItem(product),
                index: i,
                quantity: Math.ceil(product.cart.quantity),
            };
        }),
    });
}
